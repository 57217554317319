@import "variables.scss";
@media (min-width: 1920px) {
    .container {
        max-width: 70%;
    }
}

@media (max-width: 1680px) {
    .container {
        max-width: 85%;
    }
}

@media (max-width: 1275px) {
    .container {
        max-width: 95%;
    }
    .cards ul {
        grid-template-columns: repeat(4, minmax(0, 1fr));
        li {
            .postCard {
                a {
                    height: auto;
                    min-height: 300px;
                    .title {
                        font-size: 24px;
                        line-height: 30px;
                        font-weight: 600;
                    }
                }
            }
        }
    }
    .postCard {
        .title {
            font-size: 28px;
            line-height: 34px;
            max-width: 80%;
            font-weight: 600;
        }
        .description {
            font-size: 18px;
        }
    }

    .reviews {
        .bonus {
            .wrapper {
                .name {
                    display: none;
                }
                .block {
                    margin-top: 0px;
                    .logo {
                        height: 100px;
                        img {
                            max-width: 80%;
                        }
                    }
                    .specs {
                        padding: 10px 0px;
                        ul {
                            display: block;
                            li {
                                display: block;
                                padding: 10px;
                            }
                        }
                    }
                }
            }
        }
    }

    .bonusSection.theme1 {
        .action {
            flex-direction: column;
            .block {
                .value {
                    padding: 10px;
                }
            }
            .click {
                .buttonsAction {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    column-gap: 10px;
                    padding-top: 10px;
                }
            }
        }
    }
}

@media (max-width: 1100px) {
    .bonusSection.theme2 {
        grid-template-columns: 0.7fr 1fr 0.7fr;
        &.hasCat {
            grid-template-columns: 0.8fr 0.6fr 1fr;
            .specs {
                padding: 0px 0px 0px 20px;
                border-right: 0px;
            }
            .payments {
                grid-column-start: 1;
                grid-column-end: 2;
                padding-left: 0px;
            }
            .action {
                grid-column-start: 2;
                grid-column-end: 4;
            }
        }
        .specs {
            padding: 0px 20px;
            border-right: 1px solid #e1e1e1;
            height: 100%;
            display: flex;
            align-content: center;
            align-items: center;
        }

        .action {
            flex-wrap: nowrap;
            align-content: center;
            flex-direction: row;
            grid-column-start: 1;
            grid-column-end: 5;
            padding-top: 15px;
            .block {
                flex: 1;
                border: 0px;
                display: flex;
                height: auto;
                align-items: center;
            }
            .click {
                flex: 1;
            }
            .buttonsAction {
                width: 100%;
                .button {
                    background: $mainColor;
                }
            }
        }
        &.widgetAffiliateList {
            .bookmark {
                .block {
                    flex-direction: column;
                    align-content: center;
                    justify-content: center;
                    flex-wrap: nowrap;
                    align-items: center;
                }
            }
            .specs {
                border: 0px;
                align-content: flex-start;
                align-items: flex-start;
                flex-direction: column;
                justify-content: center;
                flex-wrap: nowrap;
                .infos {
                    width: 100%;
                    ul.listSpecs {
                        width: 100%;
                        padding: 0px;
                        margin: 0px;
                        li {
                            margin-left: 0px;
                            padding: 0px;
                        }
                    }
                }

                .buttonsAction {
                    .button {
                        display: flex;
                        flex: 1;
                        margin: 5px 0;
                        width: 100%;
                        padding: 0 20px;
                        text-transform: uppercase;
                        text-decoration: none;
                        border: 1px solid $mainColor;
                        color: $mainColorHoverText;
                        background: $mainColor;

                        &:hover {
                            opacity: 0.9;
                            color: $mainColorHoverText;
                        }
                        &.secundary {
                            background: transparent !important;
                            font-weight: 400;
                            font-weight: 700;
                            color: $mainColor;
                            text-transform: uppercase;
                            &:hover {
                                color: $mainColor;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 992px) {
    header {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 10000;
        transform: translateY(0);
        transition: all 0.4s ease-in-out;

        nav#mainNav {
            height: 56px;
            box-shadow: none;
            padding-top: 0.6rem !important;
            padding-bottom: 0.6rem !important;
            a.sub-mobile {
                float: right;
                padding: 8px 12px 8px 12px;
                line-height: 14px;
                margin-top: 11px;
                background: $secundaryColor;
            }
            .sub-mobile i.fas.fa-angle-down {
                color: black;
            }
            .navbar-brand {
                padding: 0px;
                img {
                    max-height: 20px;
                }
            }
            .navbar-toggler {
                background: transparent;
                padding: 0px;
            }
        }
    }

    .navbar-dark {
        .navbar-toggler-icon {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
        }
    }

    .cards ul {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        li {
            .postCard {
                a {
                    height: auto;
                    min-height: 300px;
                    .title {
                        font-size: 26px;
                        line-height: 32px;
                        font-weight: 500;
                    }
                }
            }
        }
    }

    body {
        padding-top: 56px;
    }

    // ODOS
    .odds.details {
        .dataTeams {
            .banner {
                &.hasPrognostic {
                    padding-bottom: 150px;
                    min-height: auto;
                }
                .header {
                    .titleGame {
                        .titleLeague {
                            padding-bottom: 10px;
                            font-size: $fontSizeTitleLabel;
                            line-height: $fontLHeightTitleLabel;
                        }
                        h1 {
                            font-size: $fontSizeSmallH1;
                            line-height: $fontLHeightSmallH1;
                        }
                    }
                }
                .teams {
                    grid-template-columns: 1fr 1fr 1fr 1fr;
                    -moz-column-gap: 40px;
                    column-gap: 40px;
                    .data {
                        order: 1;
                        grid-column-start: 1;
                        grid-column-end: 5;
                        .highlight {
                            grid-template-columns: 1fr 40% 1fr;
                            padding-bottom: 20px;
                            margin-bottom: 20px;
                            .team {
                                font-size: 15px;
                                line-height: 18px;
                                img {
                                    height: 74px;
                                    margin-bottom: 10px;
                                }
                            }
                            .draw {
                                justify-content: flex-start;
                                .dateGame {
                                    font-size: 14px;
                                    .date {
                                        font-size: 14px;
                                        margin-bottom: 15px;
                                    }
                                }
                                .result {
                                    font-size: 36px;
                                }
                            }
                        }
                        .odds {
                            grid-template-columns: 1fr 40% 1fr;
                            .item {
                                font-size: $fontSizeH3;
                                span {
                                    font-size: $fontSizeSmallText;
                                    margin-right: 8px;
                                }
                                i {
                                    font-size: $fontSizeH3;
                                    margin-top: 0px;
                                    margin-left: 8px;
                                }
                            }
                        }
                    }
                    .specs {
                        order: 2;
                        &:first-child {
                            grid-column-start: 1;
                            grid-column-end: 3;
                        }
                        &:last-child {
                            grid-column-start: 3;
                            grid-column-end: 5;
                        }
                    }
                }
            }
            .history {
                width: 100%;
            }
        }
    }

    .reviews {
        &.all {
            .cards {
                .listCards {
                    grid-template-columns: 1fr 1fr 1fr;
                }
            }
        }
    }
}

@media (max-width: 700px) {
    .lastArticles {
        .posts {
            padding: 20px 15px 0px 15px;
            .cards {
                ul {
                    display: block;
                    li {
                        padding-bottom: 20px;
                    }
                }
            }
        }
    }

    footer .footerGrid {
        display: flex;
        width: 100%;
        padding-bottom: 2rem;
        flex-direction: column;
    }

    .container {
        padding: 0;
        max-width: 90%;
    }

    body {
        padding-top: 56px;
        overflow-x: hidden !important;
        &.menu-fixed {
            padding-top: 56px;
        }
    }

    h1.title-post {
        font-size: 8vw;
        line-height: 10vw;
    }

    .sideBarInfos.hideAnchor {
        display: none;
    }

    img {
        max-width: 100%;
    }

    body.scroll-up {
        header {
            transform: translateY(-110%);
        }
        .sideBarInfos,
        .glossaries .filters {
            top: 0;
        }
        .ads_affiliate {
            bottom: 0px !important;
        }
    }
    footer {
        .logoFooter {
            max-width: 70%;
        }
    }

    .navbar-dark {
        .navbar-toggler-icon {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
        }
    }
    header {
        position: fixed;
        top: 0;
        width: 100%;
        transform: translateY(0);
        transition: all 0.4s ease-in-out;
        nav#mainNav {
            overflow: hidden;
            height: 56px;
            box-shadow: none;
            padding-top: 0.6rem !important;
            padding-bottom: 0.6rem !important;
            a.sub-mobile {
                float: right;

                padding: 8px 12px 8px 12px;
                line-height: 14px;
                margin-top: 11px;
                color: $secundaryColorHoverText;
                background: $secundaryColor;
            }
            .sub-mobile i.fas.fa-angle-down {
                background-color: $secundaryColor;
                color: $secundaryColorHoverText;
            }
            .navbar-brand {
                padding: 0px;
                width: 80%;
                margin-right: 0px;
                img {
                    max-height: 20px;
                    object-fit: contain;
                    width: auto;
                }
            }
            .navbar-toggler {
                background: transparent;
                padding: 0px;
            }
        }
    }

    ol.breadcrumb {
        li {
            font-size: 12px;
            line-height: 14px;
            display: inline-flex;
            a {
                text-transform: capitalize;
                display: inline-block;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                line-clamp: 1;
                -webkit-box-orient: vertical;
            }
            &:after {
                margin-right: 0px;
                padding: 0px 4px;
                display: inline-block;
            }
        }
    }

    // PageCustom
    .pageCustom {
        .bannerSection {
            height: auto !important;
            .title {
                h1 {
                    line-height: 14vw !important;
                    font-size: 15vw !important;
                    em {
                        font-size: 8vw !important;
                        line-height: 7vw !important;
                    }
                }
            }
            p {
                width: 100% !important;
                margin-top: 0px !important;
                font-size: 4vw !important;
                line-height: 6vw !important;
                max-width: 100% !important;
                padding-bottom: 2rem !important;
            }
        }
        .detailsArticle {
            .body {
                padding: 0px;
            }
        }
        .detailsArticle.ContainerHoverTop {
            .container {
                padding: 20px;
            }
        }
    }

    //Home Page
    .homepage {
        .bannerSection {
            height: auto;
            .title {
                h1 {
                    line-height: 14vw;
                    font-size: 15vw;
                    em {
                        font-size: 8vw;
                        line-height: 7vw;
                    }
                }
            }
            .description {
                margin-top: 0px;
                font-size: 4vw;
                line-height: 6vw;
                max-width: 100%;
                padding-bottom: 2rem;
            }
        }
        .betting {
            &:after {
                height: 150px;
            }
            .wrapper {
                padding: 20px 0;
                border-radius: 6px;
                .header {
                    padding: 0 20px;
                    .name {
                        strong {
                            font-size: 6.4vw;
                            letter-spacing: -0.5px;
                        }
                    }
                    .description {
                        padding-top: 15px;
                    }
                }
            }
        }
        .highlight {
            padding: 60px 0 30px;
            margin: 30px 0;
            ul {
                li {
                    padding-bottom: 50px;
                    .title {
                        font-size: 26px;
                        margin-bottom: 20px;
                        line-height: 28px;
                        span {
                            font-size: 22px;
                        }
                    }
                }
            }
        }
        .bonusItem {
            .specs {
                text-align: center;
                padding: 0.3rem 0px;
                .bonusText {
                    font-size: 12px;
                    font-weight: 700;
                }
            }
            .action {
                .button {
                    font-size: 12px;
                    font-weight: 700;
                    letter-spacing: -0.2px;
                }
            }
        }
        .bonus {
            padding: 2rem 0px;
            .header {
                padding-right: 15px;
                .title {
                    padding-bottom: 0px;
                    margin-bottom: 0px;
                    h2 {
                        margin-bottom: 2rem;
                        font-size: 10vw;
                        line-height: 14vw;
                        strong {
                            font-size: 20vw;
                            line-height: 22vw;
                        }
                    }
                }
                .description {
                    font-size: 18px;
                    line-height: 28px;
                }
            }
            .wrapper {
                margin-top: 30px;
                width: 100vw;
                overflow-x: auto;
                display: inline-block;
                margin-right: -10%;
                margin-left: -6px;
                &::-webkit-scrollbar {
                    display: none;
                }
                .listCardsBonus {
                    padding-bottom: 2rem;
                }
                .sliderBonus {
                    width: 90%;
                    margin: 0 auto;
                    grid-template-columns: repeat(1, 1fr);
                    .slick-track {
                        column-gap: 10px;
                    }
                }
                ul {
                    display: flex;
                    grid-template-columns: none;
                    justify-content: left;
                    flex-wrap: inherit;
                    column-gap: 0px;
                    padding-left: 5px;
                    li {
                        .specs {
                            .bonusText {
                                font-size: 14px;
                                padding: 10px 0px;
                            }
                        }
                    }
                }
            }
        }
        .features {
            &.halfWrapper {
                .halfContained {
                    &:before {
                        content: "";
                        position: absolute;
                        left: -100vw;
                        right: 0px;
                        bottom: 0px;
                        top: 0px;
                        z-index: 0;
                        max-height: 320px;
                    }
                }
            }
            .row {
                margin: 0;
            }
            .col-md-4-custom {
                max-width: 100%;
            }
            .halfContained {
                padding-top: 30px;
                background: transparent;
                margin: 0;
                .content-mobile {
                    position: relative;
                    z-index: 1;
                    text-align: center;
                    margin-right: 0;
                    img {
                        max-width: 80%;
                    }
                }
            }
            .description {
                padding: 0;
                h3 {
                    i {
                        margin-right: 10px;
                    }
                }
            }
        }
        .faqs {
            padding: 30px 0;
            .image {
                padding-right: 0;
            }
        }
    }
    .breadcrumbSection {
        ul {
            li {
                font-size: 12px;
                line-height: 14px;
                display: inline-flex;
                a {
                    text-transform: capitalize;
                    display: inline-block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    line-clamp: 1;
                    -webkit-box-orient: vertical;
                }
                &:after {
                    margin-right: 0px;
                    padding: 0px 4px;
                    display: inline-block;
                }
                span {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    line-clamp: 1;
                    -webkit-box-orient: vertical;
                    vertical-align: middle;
                }
            }
        }
    }

    //Reviews
    .reviews {
        .authorInfos {
            order: 2;
            .wrapper {
                position: relative;
                top: 0px;
            }
        }
        .sideBarInfos {
            order: 1;
            position: fixed;
            left: 0px;
            right: 0px;
            padding: 0px;
            top: 56px;
            z-index: 5;
            .sidebarNav {
                display: inline-block;
                max-width: calc(100vw);
                overflow-x: auto;
                border-radius: 0px;
                border-left: 0px;
                border-right: 0px;
                background: white;
                margin-right: 0px;
                margin-left: 0px;
                top: 56px;
                &::-webkit-scrollbar {
                    display: none;
                }
                ul {
                    display: flex;
                    grid-template-columns: none;
                    justify-content: left;
                    padding-left: 4px;
                    padding-top: 2px;
                    padding-bottom: 1px;
                    li {
                        text-align: center;
                        display: flex;
                        border-bottom: 0px;
                        margin: 0px;
                        padding: 4px;
                        &:last-child {
                            border: 0px;
                        }
                        a {
                            height: 100%;
                            background: rgba(240, 240, 240);
                            min-width: 200px;
                            font-size: 12px;
                            text-transform: capitalize;
                            display: inline-block;
                            padding: 10px 15px;
                        }
                    }
                }
            }
        }
        .body {
            order: 3;
            .categoryContent {
                .cat {
                    font-size: 12px;
                }
            }
            .contentInfos {
                .datePost {
                    font-size: 12px;
                    padding-top: 1.4rem;
                }
            }
        }
        .bannerReview {
            &:before {
                background: linear-gradient(
                    90deg,
                    rgba(var(--reviewMainColor), 0) 15%,
                    rgba(var(--reviewMainColor), 0.35) 20%,
                    rgba(var(--reviewMainColor), 0.95) 45%,
                    rgba(var(--reviewMainColor), 1) 60%,
                    rgba(var(--reviewMainColor), 1) 60%,
                    #01143b 100%
                );
            }
            .data {
                max-width: 100%;
                display: inline-block;
                padding-top: 20px;
                .logo {
                    width: 50%;
                    float: left;
                    img {
                        max-height: 60px;
                    }
                    .action {
                        .button {
                            width: 100%;
                            padding: 0px 10px;
                        }
                    }
                }
                .reviewData {
                    width: 50%;
                    float: left;
                    .age {
                        justify-content: end;
                        .text {
                            display: none;
                        }
                        img {
                            height: 30px;
                        }
                    }
                    .starts {
                        margin-top: 6vw;
                        justify-content: end;
                        flex-direction: row;
                        display: block;
                        text-align: center;
                        .description {
                            font-size: 4vw;
                            margin-left: 0px;
                        }
                        ul {
                            display: block;
                            li {
                                display: inline-block;
                                i {
                                    font-size: 4vw;
                                }
                            }
                        }
                    }
                }
                .specs {
                    margin-top: 25px;
                    display: inline-block;
                    max-width: 95vw;
                    overflow-x: auto;
                    &::-webkit-scrollbar {
                        display: none;
                    }
                    ul {
                        display: flex;
                        grid-template-columns: none;
                        justify-content: left;
                        li {
                            min-width: 50%;
                            padding: 15px;
                            height: auto;
                            .resume {
                                .listPayments {
                                    position: inherit;
                                    &:hover {
                                        ul {
                                            &.drop {
                                                top: calc(100% + 0px);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .bonus {
            margin-top: 20px;
            width: 100vw;
            margin-left: -5%;
            border-radius: 0px;
            padding: 0 10px;
            .wrapper {
                .name {
                    flex-direction: column;
                    .pros {
                        margin-top: 20px;
                        ul {
                            li {
                                margin-top: 10px;
                                font-size: 14px;
                            }
                        }
                    }
                }
                .block {
                    .specs {
                        ul {
                            display: flex;
                            li {
                                font-size: 12px;
                                span {
                                    font-size: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .descriptionSection {
            padding: 4rem 0px;
            p {
                font-size: 18px;
                line-height: 28px;
            }
        }

        &.inCategory {
            .bannerReview {
                &:before {
                    background: linear-gradient(
                        90deg,
                        rgba(15, 123, 91, 0) 5%,
                        rgba(1, 20, 59, 0.35) 30%,
                        rgba(1, 20, 59, 0.95) 50%,
                        #01143b 80%,
                        #01143b 90%,
                        #01143b 100%
                    );
                }
            }
        }
        &.all {
            .cards {
                .listCards {
                    grid-template-columns: 1fr;
                }
            }
        }
    }
    .featurePostCard {
        .postCard {
            min-height: 55vh;
        }
    }
    .postCard {
        a {
            padding: 20px;
            .title {
                max-width: 100%;
            }
            .description {
                display: none;
            }
            .action {
                position: relative;
                bottom: auto;
                right: auto;
                width: 100%;
            }
        }
        .tag {
            padding: 0px 10px;
            font-size: 12px;
        }
        .title {
            font-size: 26px;
            line-height: 32px;
        }
    }
    .cards ul {
        grid-template-columns: 1fr;
        li {
            .postCard {
                a {
                    height: auto;
                    min-height: 40vh;
                    .title {
                        font-size: 26px;
                        line-height: 32px;
                        font-weight: 600;
                    }
                }
            }
        }
    }

    .categories {
        display: flex;
        flex-direction: column;
        grid-template-columns: none;
        margin-top: 20px;
        width: 95vw;
        position: relative;
        .name {
            padding-right: 0;
            text-align: left;
        }

        ul {
            -moz-column-gap: 10px;
            column-gap: 10px;
            margin-top: 20px;
            display: flex;
            max-width: 100vw;
            overflow-x: auto;
            flex-wrap: nowrap;
            justify-content: start;
            padding-right: 20px;
            &::-webkit-scrollbar {
                display: none;
            }
            li {
                min-width: 100px;
                a {
                    font-size: 10px;
                    height: auto;
                    padding: 10px 0px 10px 0px;
                    .title {
                        line-height: 12px;
                    }
                }
            }
        }

        &.hasSearch {
            margin-top: 0;
            ul {
                li {
                    a {
                        height: 80px;
                        img {
                            height: 28px;
                        }
                        .title {
                            display: block;
                        }
                    }
                }
            }
        }
    }

    .bonusSection {
        padding: 20px;
        display: flex;
        flex-direction: column;
        .logo {
            height: 140px;
            width: 100%;
        }
        .specs {
            .title {
                margin-top: 20px;
            }
        }
        .action {
            width: 100%;
            margin: 20px 0 0;
            flex-direction: column;
            .block {
                margin-bottom: 20px;
                .value {
                    padding: 10px 20px;
                    font-size: 14px;
                    line-height: 20px;
                }
            }
            .click {
                width: 100%;
                flex-direction: column;
                & > div {
                    width: 100%;
                }
                .buttonsAction {
                    flex-direction: column;
                }
            }
        }
    }

    .detailsArticle {
        .wrapper {
            .author {
                position: relative;
                padding: 15px 0px;
                &:after {
                    content: "";
                    width: 100vw;
                    height: 100%;
                    background: #ffffff;
                    border-bottom: 1px solid #e5e5e5;
                    top: 0;
                    z-index: 0;
                    left: -5vw;
                    position: absolute;
                }
                .image {
                    display: none;
                }
                .description {
                    width: 100%;
                    position: relative;
                    z-index: 1;
                    ul {
                        display: flex;
                        width: 100%;
                        column-gap: 10px;
                        justify-content: space-between;
                        li {
                            margin-bottom: 0;
                            .key {
                                color: #545454;
                            }
                            .value {
                                font-size: 13px;
                            }
                        }
                    }
                }
            }
            .ads {
                display: none;
            }
            .ads_affiliate {
                position: fixed;
                bottom: -55px;
                left: 0px;
                right: 0px;
                z-index: 1000;
                display: grid;
                border-radius: 0px;
                grid-template-columns: 0.6fr 0.8fr 1fr;
                align-items: center;
                justify-items: stretch;
                justify-content: center;
                align-content: center;
                height: 50px;
                .logo {
                    height: 40px;
                    img {
                        max-height: 90%;
                        max-width: 80%;
                    }
                }
                .pros {
                    display: none;
                }
                .data {
                    margin-top: 0px;
                    background-color: transparent;
                    font-size: 0.8rem;
                    .bonusText {
                        font-size: 0.8rem;
                        line-height: 0.8rem;
                    }
                }
                .action {
                    margin-top: 0px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    align-content: center;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    .button {
                        font-size: $fontSizeSmallBtn;
                        width: 100%;
                    }
                }
            }
        }
        .body .contentBody > h2:first-child {
            padding-top: 0px;
        }
        .body {
            &.mobileTitle {
                .contentBody {
                    h1 {
                        margin-top: 0px;
                    }
                }
                .contentInfos {
                    .categoryContent {
                        .cat {
                            margin-top: 1rem;
                        }
                    }
                    .datePost {
                        padding-top: 0.4rem;
                    }
                }
            }
            padding: 00px 20px 20px;
            h1 {
                margin-top: 1rem;
                font-size: 32px;
                line-height: 40px;
                font-weight: 800;
                strong {
                    font-weight: 800;
                }
            }

            h2 {
                font-size: 22px;
                line-height: 28px;
                font-weight: 800;
                strong {
                    font-weight: 800;
                }
            }

            h3 {
                font-size: 18px;
                line-height: 28px;
                font-weight: 700;
                strong {
                    font-weight: 700;
                }
            }
            p {
                font-size: 18px;
                line-height: 28px;
            }
            ul.numlistCustom {
                padding: 4rem 0px !important;
                grid-template-columns: repeat(1, minmax(0, 1fr));
                column-gap: 10%;
                li {
                    font-size: 18px;
                    line-height: 28px;
                    strong {
                        font-size: 22px;
                        font-weight: 700;
                        line-height: 28px;
                        color: #081131;
                        padding-bottom: 5px;
                    }
                }
            }
        }
    }

    /* POSTS */
    .posts {
        .breadcrumbSection {
            border-bottom: 1px solid #e5e5e5;
        }
        .authorInfos {
            order: 2;
            padding: 0px;
            .wrapper {
                position: relative;
                top: 0px;
            }
        }
        .sideBarInfos {
            order: 1;
            z-index: 5;
            position: fixed;
            top: 56px;
            padding: 0px;
            .sidebarNav {
                display: inline-block;
                max-width: calc(100vw);
                overflow-x: auto;
                border-radius: 0px;
                border-left: 0px;
                border-right: 0px;
                background: white;
                margin-right: -10%;
                margin-left: -18px;
                top: 56px;
                &::-webkit-scrollbar {
                    display: none;
                }
                ul {
                    display: flex;
                    grid-template-columns: none;
                    justify-content: left;
                    padding-left: 4px;
                    padding-top: 2px;
                    padding-bottom: 1px;
                    li {
                        text-align: center;
                        display: flex;
                        border-bottom: 0px;
                        margin: 0px;
                        padding: 4px;
                        &:last-child {
                            border: 0px;
                        }
                        a {
                            height: 100%;
                            background: rgba(240, 240, 240);
                            min-width: 200px;
                            font-size: 12px;
                            text-transform: capitalize;
                            display: inline-block;
                            padding: 10px 15px;
                        }
                    }
                }
            }
        }
        .body {
            order: 3;
            padding: 0px;
            .categoryContent {
                .cat {
                    font-size: 12px;
                }
            }
            .contentInfos {
                .datePost {
                    font-size: 14px;
                    padding-top: 1.4rem;
                }
            }
        }
        .bannerPost {
            .data {
                .title {
                    h1 {
                        font-size: 7vw;
                        line-height: 8vw;
                    }
                }
            }
        }
        .row {
            margin: 0;
        }

        .categories {
            margin-left: -5%;
        }
    }

    .list {
        padding: 0;
        .bettingList {
            display: flex;
            flex-direction: column;
            grid-template-columns: none;
            .name {
                text-align: center;
                font-size: 5vw;
                span {
                    font-size: 7vw;
                }
            }
            ul {
                margin: 2rem 0px 0px 0px;
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 10px;
                li {
                    a {
                        height: 60px;
                    }
                }
            }
        }
    }

    /* GLOSSARY */
    .glossaries {
        .bannerPost {
            .description {
                margin-top: 10px;
            }
        }
        .subtitle {
            color: #6e6e6e;
            h2 {
                font-size: 26px;
                font-weight: 800;
            }
        }
        .filters {
            flex-direction: column;
            align-items: stretch;
            top: 58px;
            .search {
                padding-right: 0;
                padding-bottom: 10px;
                input {
                    height: 55px;
                }
            }
            .alphas {
                overflow-x: auto;
                flex: none;
                width: 100%;
                &::-webkit-scrollbar {
                    display: none;
                }
                ul {
                    justify-content: left;
                    flex-wrap: inherit;
                    li {
                        min-width: 42px;
                    }
                }
            }
        }
        .data {
            .group {
                .letter {
                    .pointer {
                        top: -150px;
                    }
                }
                ul {
                    grid-template-columns: none;
                }
            }
        }
    }

    //Widgets
    .widget {
        padding: 2rem 0px;
        display: flex;
        position: relative;
        width: 100%;

        @for $i from 1 through 30 {
            &.affiliate:nth-of-type(#{$i}),
            &.affiliate:nth-of-type(#{$i}) .widget_affiliate {
                z-index: 9 - $i;
            }
        }

        .widgetBody {
            position: relative;
            &.widget_text {
                border-radius: 5px;
                display: flex;
                background: #0b3691;
                align-content: center;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                padding: 5rem 5vw 4.5rem 5vw;
                text-align: center;
                background-image: url("/images/widgets/widget_points_left.svg"), url("/images/widgets/widget_points_right.svg"),
                    url("/images/widgets/widget_arrows_left.svg"), url("/images/widgets/widget_arrows_right.svg");
                background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
                background-position: 25px 25px, calc(100% - 25px) calc(100% - 25px), top left, bottom right;
                background-size: 40%, 40%, contain, contain;

                .label {
                    position: relative;
                    margin-top: calc(-4rem - 14px);
                    margin-bottom: 3rem;
                    left: auto;
                    padding: 4px 20px;
                    background-color: #f9dc1c;
                    border-radius: 4px;
                    text-align: center;
                    font-size: 12px;
                    font-weight: 600;
                    color: #000000;
                }

                .title {
                    font-size: 22px;
                    font-weight: 800;
                    line-height: 30px;
                    color: #ffffff;
                }
                .description {
                    padding-top: 0.5rem;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                    color: #ffffff;
                }
            }

            &.widget_affiliate {
                top: auto !important;
                bottom: 10px;
                z-index: 999;
                padding: 5px 5px;
                column-gap: 0px;
                display: grid;
                flex-direction: row;
                grid-template-columns: 1fr 1fr auto;
                transition: none;
                text-decoration: none !important;
                z-index: 2;
                .label {
                    display: none;
                }
                .logo {
                    order: 1;
                    height: 45px;
                    padding: 0.5rem 0px;
                    width: 60px;
                    margin-right: 10px;
                }
                .title {
                    padding: 5px 0.5rem;
                    text-align: center;
                    font-size: 12px;
                    line-height: 16px;
                    order: 3;
                    grid-column-end: 3;
                    grid-column-start: 1;
                    height: 42px;
                    display: none;
                }
                .affiliateLogo {
                    display: grid;
                    flex-direction: row;
                    align-content: center;
                    align-items: center;
                    grid-template-columns: 1fr 1fr;
                    white-space: nowrap;
                    .brandName {
                        display: none;
                    }
                }
                .bonusMobile {
                    display: flex;
                    order: 2;
                    justify-content: center;
                    font-weight: 700;
                    font-size: $fontSizeText;
                    color: black !important;
                }
                .action {
                    order: 2;
                    justify-content: center;
                    padding-left: 0.5rem;
                    .button {
                        padding: 0 5px;
                    }
                }
                &.last {
                    position: fixed;
                    width: calc(100vw - 49px);
                    margin: 0;
                }
            }

            &.widget_image {
                display: flex;
                align-content: center;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                text-align: center;

                .widgetInside {
                    padding: 1.3rem 1.8rem 0px 1.8rem;
                    position: relative;
                    z-index: 2;
                    .title {
                        padding: 0px 3vw;
                        font-size: 18px;
                        font-weight: 800;
                        line-height: 24px;
                        color: #ffffff;
                    }
                    .description {
                        padding: 0.5rem 3vw 0px 3vw;
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 22px;
                        color: #ffffff;
                    }
                    .image {
                        padding-top: 1rem;
                        img {
                            width: 100%;
                            margin: 0px;
                        }
                    }
                }

                .bgWidget {
                    position: absolute;
                    z-index: 1;
                    top: 0px;
                    left: 0px;
                    right: 0px;
                    border-radius: 5px;
                    background: #0b3691;
                    width: 100%;
                    height: 70%;
                    background-image: url("/images/widgets/widget_points_bottom.svg"), url("/images/widgets/widget_points_top.svg"),
                        url("/images/widgets/widget_arrows_down.svg"), url("/images/widgets/widget_arrows_up.svg");
                    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
                    background-position: 10px calc(100% - 10px), calc(100% - 10px) 10px, bottom left, top right;
                    background-size: 10px, 10px, 12%, 12%;
                }
            }

            &.widget_list {
                display: flex;
                align-content: center;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                text-align: left;

                ul.listWidget {
                    list-style: none;
                    padding: 0px;
                    margin: 0px;
                    display: grid;
                    align-content: center;
                    justify-content: center;
                    align-items: center;
                    grid-template-columns: repeat(1, minmax(0, 1fr));
                    column-gap: 0px;
                    grid-row-gap: 2rem;
                    li {
                        padding: 0px;
                        margin: 0px;
                        display: flex;
                        flex-direction: column;
                        list-style: none;
                    }
                }

                .title {
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 30px;
                    color: #081131;
                    .number {
                        font-size: 14px;
                        line-height: 24px;
                        font-weight: 600px;
                        color: #f9dc1c;
                        background: #0b3691;
                        border-radius: 100px;
                        text-align: center;
                        margin-right: 5px;
                        width: 24px;
                        height: 24px;
                        display: inline-block;
                        vertical-align: middle;
                        margin-top: -4px;
                    }
                }
                .description {
                    padding-top: 0.5rem;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    color: #081131;
                }
            }

            &.widget_payments {
                padding: 1rem;
                .infosList {
                    column-gap: 10px;
                    flex-direction: column;
                    .image {
                        padding: 1rem 0.5rem;
                        background: #f9f9fa;
                        width: 70%;
                        img {
                            max-height: 50px;
                            object-fit: contain;
                            width: 100%;
                            margin: 0px;
                        }
                    }
                    .title {
                        font-size: $fontSizeH3;
                        line-height: $fontSizeH3;
                        text-align: center;
                    }
                    padding-bottom: 0.6rem;
                }
                .listPayments {
                    flex-direction: column;
                    .sectionList {
                        width: 100%;
                        .title {
                            padding-bottom: 10px;
                        }
                        .title:nth-of-type(1) {
                            padding-top: 1rem;
                        }
                    }
                    .card {
                        .card-header {
                            cursor: pointer;
                            background: #f9f9fa;
                            border-radius: 5px;
                            border: 0px;
                            margin: 5px 0px;
                            padding: 15px;
                            display: flex;
                            flex-direction: row;
                            flex-wrap: nowrap;
                            align-content: center;
                            align-items: center;
                            column-gap: 30px;
                            &:hover {
                                background: #f4f4f4;
                            }
                            .image {
                                padding: 0px;
                                border-radius: 5px;
                                max-width: 120px;
                                width: 60%;
                                img {
                                    max-height: 26px;
                                    object-fit: contain;
                                    width: 100%;
                                    margin: 0px;
                                }
                            }
                            h4 {
                                text-transform: capitalize;
                                font-weight: 400;
                                width: 100%;
                                font-size: $fontSizeText;
                                line-height: $fontLHeightText;
                            }
                            .arrow {
                                .btnArrow {
                                    color: #ffffff;
                                    border-radius: 4px;
                                    line-height: 28px;
                                    text-align: center;
                                    width: 24px;
                                    height: 24px;
                                    background: $titleColor;
                                }
                            }
                        }
                    }
                }

                .title {
                    font-size: 22px;
                    font-weight: 700;
                    line-height: 30px;
                    color: #081131;
                    .number {
                        font-size: 14px;
                        line-height: 24px;
                        font-weight: 600px;
                        color: #f9dc1c;
                        background: #0b3691;
                        border-radius: 100px;
                        text-align: center;
                        margin-right: 5px;
                        width: 24px;
                        height: 24px;
                        display: inline-block;
                        vertical-align: middle;
                        margin-top: -4px;
                    }
                }
                .description {
                    padding-top: 0.5rem;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 22px;
                    color: #081131;
                }
            }
        }
    }

    //BonusItem

    li.listBettingItem {
        padding-bottom: 15px;
    }

    .bonusSection.theme2 {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        flex-wrap: nowrap;

        .bookmark {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-content: center;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding-bottom: 15px;
            padding-right: 0px;
            border-right: 0px;
            border-bottom: 1px solid #e1e1e1;
            .logo {
                flex: 1;
                padding: 2% 0px;
            }
            .starts {
                flex: 1;
                ul {
                    li {
                        margin: 0 2px;
                        line-height: 22px;
                        i {
                            font-size: 12px;
                            line-height: 12px;
                            color: $secundaryColor;
                        }
                    }
                }
                .description {
                    font-weight: 600;
                    font-size: 12px;
                    border-radius: 100px;
                    width: 38px;
                    height: 38px;
                    min-width: 34px;
                    min-height: 34px;
                    text-align: center;
                    line-height: 32px;
                    border: 3px solid #ffffff;
                    background: $secundaryColor;
                    color: $secundaryColorHoverText;
                }
            }
        }
        &.hasCat {
            .specs {
                padding-top: 0px;
                padding-left: 0px;
                padding-bottom: 20px;
            }
        }
        &.widgetAffiliateList {
            margin-bottom: 2rem;
            .bookmark {
                .block {
                    flex: 1;
                }
            }
        }
        .specs {
            padding: 20px 0px;
            border-right: 0px;
            border-bottom: 1px solid #e1e1e1;
            height: 100%;
            display: flex;
            align-content: center;
            align-items: center;
            width: 100%;
            .infos {
                font-size: 22px;
                line-height: 28px;
                ul.listSpecs {
                    li {
                        font-size: 15px;
                        line-height: 22px;
                        margin: 5px 0px;
                    }
                }
            }
        }

        .catList {
            padding-top: 20px;
            padding-left: 0px;
            padding-right: 0px;
            border-right: 0;
            width: 100%;
        }

        .payments {
            padding: 20px 0px 0px 0px;
            border-right: 0px;
            width: 100%;
            .title {
                text-align: left;
                font-size: 17px;
                padding-bottom: 15px;
            }
        }

        .action {
            flex-wrap: nowrap;
            align-content: center;
            flex-direction: column;
            grid-column-start: 1;
            grid-column-end: 4;
            padding-top: 0px;
            .block {
                flex: 1;
                border: 0px;
                display: flex;
                height: auto;
                align-items: center;
                margin: 0px;
                padding-bottom: 10px;
                flex-direction: row;
                flex-wrap: nowrap;
                align-content: center;
                justify-content: center;
                margin-bottom: 10px;
                .key {
                    display: flex;
                    flex: 1;
                    margin: 0px;
                    padding: 0px;
                    flex-wrap: nowrap;
                    align-content: center;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                }
                .value {
                    display: flex;
                    flex: 1;
                }
            }
            .click {
                flex: 1;
            }
            .buttonsAction {
                width: 100%;
                flex-direction: row;
                display: flex;
                column-gap: 15px;
                .button {
                    background: $mainColor;
                }
            }
        }
    }

    .listData {
        .name {
            .barMenuSelect {
                display: flex;
                width: 100%;
                .selectDate {
                    display: flex;
                    flex-direction: row;
                    align-content: center;
                    justify-content: center;
                    align-items: center;
                    flex: 1;
                }
                .selectLeague {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    align-content: center;
                    justify-content: center;
                    align-items: center;
                    flex: 1;
                }
            }
        }
    }

    .odds {
        .listOrderMobile {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-content: stretch;
            justify-content: space-evenly;
            align-items: stretch;
            .sidebar {
                order: 2;
            }
            .data {
                order: 1;
            }
        }

        .wrapper {
            .sidebar.right {
                padding-left: 15px;
            }
            .sidebar.left {
                padding-right: 15px;
            }
        }
        .event {
            width: 100% !important;
            min-height: 100px !important;
            display: block !important;
            box-shadow: 0 3px 6px rgba(black, 0.15);
            grid-template-columns: 70px 1fr 70px !important;
            margin-bottom: 15px;
            justify-content: space-between;
            align-items: center;
            border-radius: 10px;
            padding: 10px 15px;
            background: white !important;
            font-size: 14px;
            text-align: center;
            &:hover {
                cursor: pointer;
                background: rgba(#e0e0e0, 0.2);
            }
            .date {
                display: block;
                line-height: 18px;
                text-transform: uppercase;
                width: 100%;
                .previsionBet {
                    text-transform: uppercase;
                    font-size: 0.7rem;
                    margin-top: 5px;
                    margin-bottom: -5px;
                    i {
                        margin-right: 5px;
                        color: $secundaryColor;
                    }
                }
            }
            .data {
                width: 100%;
                display: grid !important;
                grid-template-columns: 1fr 120px 1fr;
                padding-right: 0 !important;
                text-align: center;
                .home {
                    text-align: right;
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    align-content: center;
                    justify-content: center;
                    align-items: center;
                    .team {
                        justify-content: end;
                    }
                }
                .draw {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    align-content: center;
                    justify-content: center;
                    align-items: center;
                }
                .away {
                    text-align: left;
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    align-content: center;
                    justify-content: center;
                    align-items: center;
                }
                .team {
                    font-size: 12px;
                    line-height: 14px;
                    font-weight: 600;
                    text-transform: uppercase;
                    display: inline-block;
                    width: 100%;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    justify-content: flex-start !important;
                    align-content: center;
                    text-align: center;
                    min-height: 70px;
                    .nameTeam {
                        display: flex;
                        order: 2;
                        padding-top: 6px;
                    }
                    .img {
                        min-width: 60px;
                        text-align: center;
                        display: flex;
                        order: 1;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        flex-wrap: nowrap;
                        align-content: center;
                        img {
                            height: 40px;
                        }
                    }
                }
                .result {
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 40px;
                    min-height: 70px;
                }
                .oddValue {
                    display: inline-block;
                    color: $mainColor;
                    font-size: 12px;
                    font-weight: bold;
                    padding: 4px 10px;
                    line-height: 14px;
                    margin-top: 15px;
                    min-width: 60px;
                    text-align: center;
                    background: $secundaryColor;
                }
                .postponed {
                    margin-top: 15px;
                    display: inline-block;
                }
            }
            .bet {
                text-transform: uppercase;
                font-size: 14px;
                font-weight: bold;
                display: flex;
                justify-content: end;
                padding: 1rem 0px 0.5rem 0px;
                justify-content: center;
                a {
                    img {
                        max-height: 20px;
                    }
                }
            }
            .gameResult {
                width: 26px;
                height: 26px;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                font-weight: 700;
                font-size: 16px;
                background: #14dc4b;
                text-align: right;
                &.type_D {
                    background: #dc0000;
                }
            }
        }

        &.details {
            .dataTeams {
                .history {
                    .nav {
                        display: grid;
                        flex-direction: row;
                        flex-wrap: wrap;
                        align-content: center;
                        align-items: stretch;
                        justify-items: stretch;
                        grid-template-columns: 1fr 1fr 1fr;
                        li {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: nowrap;
                            align-content: center;
                            justify-content: center;
                            align-items: stretch;
                            a {
                                display: flex;
                                align-items: center;
                                padding: 14px 10px;
                                white-space: inherit;
                                text-align: center;
                                font-size: 14px;
                                line-height: 14px;
                            }
                        }
                    }
                }
            }
        }
    }
    // Errors Page

    .errorspage {
        .bannerSectionErrors {
            height: 75vh;
            min-height: 500px;
            .container {
                position: relative;
                z-index: 11;
            }
            .title {
                h1 {
                    padding-bottom: 1rem;
                }
            }
            .description {
                max-width: 90%;
            }
            .actions {
                display: flex;
                justify-content: space-around;
                align-content: center;
                flex-direction: column;
                align-items: stretch;
                row-gap: 10px;
            }
        }
        .listBetAff {
            padding: 2rem 0px;
        }
    }
}
